// ヘッダー固定 - htmlに余白設定
$(function(){
	var headerHeight = $('header').outerHeight();
	$('body').css('padding-top', headerHeight);
});

// ヘッダー固定 - htmlに余白設定
$(function() {
	var $win = $(window);
	$header = $('header');
	var animationStartPosition = $('main').offset();
	var animationStartPosition = animationStartPosition.top;
	var animationClass = 'animation';
	$win.on('load scroll', function() {
		var value = $(this).scrollTop();
		if ( value > animationStartPosition ) {
			$header.addClass(animationClass);
		} else {
			$header.removeClass(animationClass);
		}
	});
});

// スムーススクロール
// ignoreでmodaaalを除外
$(function(){
	var scroll = new SmoothScroll('a[href*="#"]', {
		header: '#header',
		ignore: '.js'
	});
});

// modaalでメニュー表示
$(function() {
	$('.js-snav').modaal({
		fullscreen: true
	});
});


// 商品切り換え
jQuery(function($){
	$('.product-tab').click(function(){
		$('.active').removeClass('active');
		$(this).addClass('active');
		$('.show').removeClass('show');
		const index = $(this).index();
		$('.product-panel').eq(index).addClass('show');
	});
});


// よくある質問アコーディオン
jQuery(function(){
	jQuery('.js-question').on('click',function(){
		var targetAnswerID = jQuery(this).attr('data-question-id');
		console.log(targetAnswerID);
		jQuery('[data-faq-id="' + targetAnswerID + '"]').toggleClass('active');
		jQuery('[data-answer-id="' + targetAnswerID + '"]').toggleClass('active');
	});
});

// 取扱製品タブ切り換え
jQuery(function($){
	$('.product-tab').click(function(){
		$('.is-active').removeClass('is-active');
		$(this).addClass('is-active');
		$('.is-show').removeClass('is-show');
		const index = $(this).index();
		$('.product-panel').eq(index).addClass('is-show');
	});
});
